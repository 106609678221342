<template>
  <div :class="isMobile && `fixed inset-0 z-50 min-h-screen overflow-y-scroll w-screen bg-white dark:bg-[#121212]`">
    <div class="w-full min-h-full relative">
      <!-- Navbar header -->
      <div
        class="w-full px-3 flex items-center justify-between h-12 bg-Tourscanner_blue-source text-white md:hidden dark:bg-Tourscanner_blue-900"
      >
        <button @click="activeCategory ? (activeCategory = null) : (activeItem = null)">
          <span v-show="activeItem" class="flex">
            <img src="/img/icons/chevron_left.svg" class="filter invert dark:invert-1" /> {{ t('Go back') }}
          </span>
        </button>
        <img
          src="/img/icons/close.svg"
          class="filter invert dark:invert-1"
          @click="
            emits('closeMobileNav');
            resetActiveStates();
          "
        />
      </div>
      <!-- Navbar Header End -->
      <!-- Navbar body -->
      <div
        class="relative min-h-screen pb-20 flex flex-col md:w-screen md:pb-0 md:min-h-full md:h-fit md:flex-row md:gap-6 md:items-start"
      >
        <div class="absolute -left-20 w-full bottom-0" />
        <!-- The menu has 3 main sections n0 ( The main menu ), n1 ( Menu subsection ) and n3 (The destinations) -->
        <!-- n0 / Items / Title / Main Items / Extra links(mobile only) -->
        <h3
          v-if="
            ![
              'company',
              'contact',
              'cookies',
              'destination',
              'faq',
              'privacy',
              'search',
              'terms-of-service',
              'allCMSPages',
            ].includes(route?.name) && navItems?.length
          "
          class="w-full flex items-center gap-3 p-3 text-4xl border-b-2 border-Tourscanner_blue-source md:hidden"
        >
          <img src="/img/icons/language.svg" class="h-8 w-8 dark:invert dark:invert-1" />
          {{ t('Explore') }}
        </h3>
        <ul
          v-if="
            ![
              'company',
              'contact',
              'cookies',
              'destination',
              'faq',
              'privacy',
              'search',
              'terms-of-service',
              'allCMSPages',
            ].includes(route?.name)
          "
          class="w-full flex flex-col md:flex-row pl-4 md:pl-0"
        >
          <li
            v-for="(item, index) in navItems"
            :key="index"
            class="text-lg group relative border-b pt-2 md:border-b-0 md:py-0 dark:border-gray-800"
          >
            <a
              href="#"
              :class="`relative font-medium text-lg mr-6 py-2 transition duration-150 ease-in-out flex items-center  dark:text-white ${
                activeItem == item.name ? 'text-Tourscanner_blue-source' : 'text-gray-700'
              }`"
              v-on="
                !isMobile
                  ? {
                      mouseenter: () => {
                        onMouseEnter(item);
                        handleMainMenuTrack(item.name);
                      },
                      mouseleave: onMouseLeave,
                    }
                  : { click: () => (activeItem = item.name) }
              "
            >
              {{ item.name }}
              <span
                class="hidden absolute bottom-0 right-1/2 w-0 translate-x-1/2 h-[2px] bg-Tourscanner_blue-source bg-opacity-65 transition-all duration-400 ease-in-out md:block md:group-hover:w-full"
                :class="activeItem == item.name ? 'w-full' : ''"
              ></span>
              <img src="/img/icons/chevron-right.svg" class="md:hidden pl-4 dark:filter dark:invert dark:invert-1" />
              <img
                src="/img/icons/chevron-down-b.svg"
                class="hidden md:block pl-4 dark:filter dark:invert dark:invert-1"
                :class="[activeItem == item.name && 'custom-filter']"
              />
            </a>
          </li>
        </ul>
        <!-- Extra links - Only shown in mobile / Other links in the nav. Change language, currency, etc... -->
        <h3
          v-if="    ![
              'company',
              'contact',
              'cookies',
              'destination',
              'faq',
              'privacy',
              'search',
              'terms-of-service',
              'allCMSPages',
            ].includes(route?.name) && navItems?.length"
          class="w-full flex items-center gap-3 mt-4 p-3 text-4xl border-b-2 border-Tourscanner_blue-source md:hidden"
        >
          <img src="/img/icons/language.svg" class="h-8 w-8 filter dark:invert dark:inver-1" />
          {{ t('Others') }}
        </h3>
        <ul>
          <li
            v-for="item in menuItems"
            :key="item.id"
            class="relative border-b py-2 dark:border-gray-800 md:hidden md:group"
            @click="item.action"
          >
            <p
              href="#"
              class="text-gray-700 md:hover:text-Tourscanner_blue-source font-medium text-xl px-3 py-2 rounded-md transition duration-150 ease-in-out flex items-center dark:text-white"
            >
              <img
                :src="item.icon"
                class="md:hidden pr-4 filter brightness-0 contrast-200 dark:invert dark:brightness-0 dark:contrast-200"
              />

              <span>{{ t(item.label) }}</span>
            </p>
          </li>
        </ul>
        <!-- End of extra links -->
      </div>
      <div
        v-if="activeItem"
        class="transition-all w-full h-full duration-300 ease-in-out absolute top-12 bg-white dark:bg-[#121212] md:h-80 md:w-screen md:border-b md:border-gray-200 md:grid md:grid-cols-[25%_75%] md:grid-rows-1 md:-left-20 md:px-16 md:top-12 md:shadow-lg md:overflow-hidden"
        @mouseleave="activeItem = null"
      >
        <!-- n1 CATEGORY / Submenu -->
        <div class="w-full h-full md:border-r md:border-gray-200 md:pr-6">
          <h3 class="w-full flex items-center gap-3 p-3 text-3xl border-b-2 border-Tourscanner_blue-source md:hidden">
            <img src="/img/icons/language.svg" class="h-8 w-8 filter dark:invert dark:invert-1" />
            {{ activeItem }}
          </h3>
          <ul class="w-full h-full md:flex md:flex-col md:gap-6 md:py-4">
            <li
              v-for="(category, catIndex) in getActiveItemCategories"
              :key="catIndex"
              class="md:group/category cursor-pointer border-b py-4 pl-4 md:border-b-0 md:py-0 md:pl-8 dark:border-gray-800"
              v-on="
                !isMobile
                  ? {
                      mouseenter: () => {
                        activeCategory = category.name;
                      },
                    }
                  : {
                      click: () => {
                        activeCategory = category.name;
                        handleSubMenuTrack(category.name);
                      },
                    }
              "
            >
              <span
                :class="`text-lg md:text-2xl md:hover:text-Tourscanner_blue-source font-medium transition duration-150 ease-in-out flex items-center  ${
                  activeCategory == category.name ? 'text-Tourscanner_blue-source' : 'text-gray-800 dark:text-white'
                }`"
              >
                <!-- ToDo: Add images instead of icons -->
                <img src="/img/icons/location_on.svg" class="pr-5 filter dark:invert dark:invert-1" />
                {{ category.name }}
                <img src="/img/icons/chevron_right.svg" class="pl-5 filter dark:invert dark:invert-1 md:hidden" />
              </span>
            </li>
          </ul>
        </div>
        <!-- Category Items N2 -->
        <div
          :class="`${
            activeCategory && isMobile && 'min-h-full'
          } max-w-[95%] absolute top-0 bg-white dark:bg-[#121212] md:static md:p-2`"
        >
          <div
            v-if="activeCategory"
            :key="activeCategory"
            class="grid text-ellipsis md:grid-cols-4 md:grid-rows-5 md:gap-y-4 md:gap-x-4"
          >
            <h3 class="w-full flex items-center gap-3 p-3 text-4xl border-b-2 border-Tourscanner_blue-source md:hidden">
              <img src="/img/icons/language.svg" class="h-8 w-8 filter dark:invert dark:invert-1" />
              {{ activeCategory }}
            </h3>
            <div v-for="(subItem, subIndex) in getActiveCategoryItems" :key="subIndex" class="md:group">
              <nuxt-link
                @click="
                  handleDestinationTrack(subItem.label);
                  resetActiveStates();
                  emits('closeMobileNav');
                "
                :to="subItem.url"
                class="flex gap-4 items-center p-2 rounded-lg md:hover:bg-blue-50 transition duration-150 ease-in-out dark:text-white dark:md:hover:bg-Tourscanner_blue-900 dark:md:hover:bg-opacity-50"
              >
                <SmartImg
                  :src="subItem.featured_image"
                  class="size-16 md:size-12 rounded-lg object-cover object-center"
                  sizes="(max-width: 767px) 210px, 280px"
                />
                <span
                  className="h-16 md:h-14 w-full flex flex-col overflow-hidden"
                >
                  <h3
                    class="max-w-[95%] overflow-y-clip break-words leading-tight text-md font-semibold text-gray-800 md:group-hover:text-Tourscanner_blue-700 md:text-[0.8rem] dark:text-white"
                    :class="{
                      'full-height': activeItem === 'Guides' && !subItem.number_of_activities,
                      'minimized-height': subItem.number_of_activities,
                    }"
                  >
                    {{ subItem.label }}
                  </h3>
                  <div
                    v-if="subItem.number_of_activities"
                    class="flex flex-col justify-evenly gap-1 text-sm w-full text-gray-600 custom-paragraph dark:text-white md:py-2 md:text-xs"
                  >
                    <span class="items-center gap-2 md:flex">
                      {{ subItem.number_of_activities }} {{ t('Activities') }}
                    </span>
                  </div>
                </span>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref } from 'vue';

const mixpanel = useMixpanel();

const { isMobile, isIos } = useDevice();
const { t, locale } = useI18n();

const route = useRoute();

// Only used in mobileA
const emits = defineEmits(['closeMobileNav', 'openContactUsChat', 'showSettingsFor']);

const props = defineProps({
  isMobileNavOpen: {
    type: Boolean,
    default: false,
  },
  navItems: {
    type: Array,
  },
});

const scrollPosition = ref(0);

// n0 ex. "Places To Visit"
const activeItem = ref(null);

// n1 ex. "Top Places" which is in the "Places To Visit" category
const activeCategory = ref(null);

const timer = ref(null);
const delay = 300; // Delay in milliseconds

const menuItems = [
  {
    id: 'about',
    label: 'About Us',
    icon: '/img/icons/ts_heart.svg',
    action: () => {
      window.open('https://tourscanner.com/about-us', '_blank');
    },
  },
  {
    id: 'faq',
    label: 'Frequently Asked Questions',
    icon: '/img/icons/contact_support.svg',
    action: () => {
      window.open('https://tourscanner.com/faq', '_blank');
    },
  },
  {
    id: 'download',
    label: 'Download our App',
    icon: '/img/icons/smartphone.svg',
    action: () => {
      const url = isIos
        ? 'https://apps.apple.com/us/app/tourscanner-compare-tours/id1496550592'
        : 'https://play.google.com/store/apps/details?id=co.tourscanner.tourscannerwebview&hl=en&pli=1';
      window.open(url, '_blank');
    },
  },
  {
    id: 'language',
    label: 'Change Language',
    icon: '/img/icons/translate.svg',
    action: () => {
      emits('showSettingsFor', 'language');
    },
  },
  {
    id: 'currency',
    label: 'Change Currency',
    icon: '/img/icons/universal_currency_alt.svg',
    action: () => {
      emits('showSettingsFor', 'currency');
    },
  },
  {
    id: 'support',
    label: 'Contact Support',
    icon: '/img/icons/contact_support.svg',
    action: () => {
      resetActiveStates();
      emits('closeMobileNav');
      emits('openContactUsChat');
    },
  },
];

// Gets the active category n1
const getActiveItemCategories = computed(() => {
  const item = props.navItems.find((item) => item.name === activeItem.value);
  activeCategory.value = !isMobile ? item.categories[0].name : null;
  return item ? item.categories : [];
});

// Gets the destinations n2
const getActiveCategoryItems = computed(() => {
  const item = props.navItems.find((item) => item.name === activeItem.value);
  if (!item) return [];
  const category = item.categories.find((cat) => cat.name === activeCategory.value);
  return category ? category.items : [];
});

// Function to reset active states
const resetActiveStates = () => {
  activeItem.value = null;
  activeCategory.value = null;
};

// Delay mechanism for desktop hover over the menu. Used to remedy accidental hovers.
const onMouseEnter = (item) => {
  clearTimeout(timer.value);
  timer.value = setTimeout(() => {
    activeItem.value = item.name; // Set active item after delay
  }, delay);
};

const onMouseLeave = () => {
  clearTimeout(timer.value);
};

const disableScroll = () => {
  scrollPosition.value = window.pageYOffset;
  document.body.style.position = 'fixed';
  document.body.style.top = `-${scrollPosition.value}px`;
  document.body.style.width = '100%';
};

const enableScroll = () => {
  document.body.style.position = '';
  document.body.style.top = '';
  document.body.style.width = '';
  window.scrollTo(0, scrollPosition.value);
};

const onKeyDown = (event) => {
  if (event.key === 'Escape') {
    resetActiveStates();
  }
};

// Trackers
const handleMainMenuTrack = (menuName) => {
  mixpanel.track('NavMenu Tap', { name: menuName, type: 'Main Menu' });
};

const handleSubMenuTrack = (subMenuName) => {
  mixpanel.track('NavMenu Tap', { name: subMenuName, type: 'Sub Menu' });
};

const handleDestinationTrack = (destination) => {
  mixpanel.track('NavMenu Tap', { name: destination, type: 'Destination' });
};

// Add scroll event listener on mount
onMounted(() => {
  !isMobile && window.addEventListener('scroll', resetActiveStates);
  isMobile && disableScroll();
  window.addEventListener('keydown', onKeyDown);
});

// Remove scroll event listener on unmount
onUnmounted(() => {
  !isMobile && window.removeEventListener('scroll', resetActiveStates);
  isMobile && enableScroll();
  window.removeEventListener('keydown', onKeyDown);
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

/* Add this to create a subtle blue overlay effect when hovering over the main nav items */
.md:group:hover::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #009ad6;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.md:group:hover::after {
  transform: scaleX(1);
}

.custom-paragraph {
  margin: 0;
  /* Remove any default margin */
  line-height: 1.2;
  /* Adjust line height as needed */
}

.full-height {
  height: 100%;
}

.minimized-height {
  height: 60%;
}

.custom-filter {
  filter: invert(53%) sepia(100%) saturate(525%) hue-rotate(175deg);
}
</style>
